import { Big } from 'big.js';
import { resolveNumberOfDecimals } from '.';
import {
  ApplyPoints,
  CheckoutItem,
  CheckoutItemMonetaryPrice,
  CheckoutItemType,
  MonetaryCurrency,
} from '@checkout/shared/src/model';

export interface CheckoutItemWithPointsAndMonetaryPrice extends CheckoutItem {
  price: { points: number; monetary: CheckoutItemMonetaryPrice };
}

export interface CheckoutItemWithOnlyMonetaryPrice extends CheckoutItem {
  price: { monetary: CheckoutItemMonetaryPrice; points: never };
}

// every item can ONLY be paid with monetary value
export const containsOnlyMonetaryPayments = (items: CheckoutItem[]): boolean => items.every(hasOnlyMonetaryPayment);

// item can ONLY be paid with monetary value
export const hasOnlyMonetaryPayment = (item: CheckoutItem): boolean => typeof item.price.points === 'undefined';

// every item can ONLY be paid with points
export const containsOnlyPointsPayments = (items: CheckoutItem[]): boolean => items.every(hasOnlyPointsPayment);

// item can ONLY be paid with points
export const hasOnlyPointsPayment = (item: CheckoutItem): boolean => typeof item.price.monetary === 'undefined';

// item CAN be paid with points (or monetary value)
export const hasPointsPrice = (item: CheckoutItem): boolean => typeof item.price.points !== 'undefined';

export const hasMonetaryPrice = (item: CheckoutItem): boolean => typeof item.price.monetary !== 'undefined';

export const getNumberOfDecimals = (checkoutItems: CheckoutItem[]): number =>
  resolveNumberOfDecimals(checkoutItems.find(hasMonetaryPrice)?.price?.monetary?.amount ?? '0.00');

export const getTotalMonetaryPrice = (checkoutItems: CheckoutItem[], numberOfDecimals: number): string =>
  checkoutItems
    .filter(hasMonetaryPrice)
    .reduce((sum, item) => sum.add(Big(item.price?.monetary?.amount ?? 0)), Big(0))
    .toFixed(numberOfDecimals);

export const isAwardFlight = (checkoutItems: CheckoutItem[]): boolean => {
  return (
    checkoutItems &&
    checkoutItems.length >= 2 &&
    checkoutItems.some(isAwardFlightItem) &&
    checkoutItems.some(isAwardTaxItem) &&
    checkoutItems.every((item) => isAwardFlightItem(item) || isAwardTaxItem(item) || isTravelExtra(item))
  );
};

export const getPointsUsedForAwardFlights = (checkoutItems: CheckoutItem[]): ApplyPoints[] => {
  return checkoutItems.filter(isAwardFlightItem).flatMap((item) => {
    const amount = item.price.points;
    return !amount ? [] : [{ checkoutItemIds: [item.id], amount }];
  });
};

export const isAwardTaxItem = (item: CheckoutItem) =>
  item.type === CheckoutItemType.TAX && hasOnlyMonetaryPayment(item) && !item.payableWithGiftCard;

export const isAwardFlightItem = (item: CheckoutItem) =>
  item.type === CheckoutItemType.FLIGHT && hasOnlyPointsPayment(item);

export const isSplitPointsFlightItem = (item: CheckoutItem) =>
  item.type === CheckoutItemType.FLIGHT && hasOnlyMonetaryPayment(item) && item.splitWithPointsSupported;

export const isTravelExtra = (item: CheckoutItem) => ancillaryCheckoutItemTypes.includes(item.type);

export const isTravelExtraWithPoints = (item: CheckoutItem) =>
  item.type !== CheckoutItemType.FLIGHT && hasPointsPrice(item);

export const getMonetaryCurrency = (checkoutItems: CheckoutItem[]): MonetaryCurrency =>
  checkoutItems.find(hasMonetaryPrice)?.price.monetary?.currency as MonetaryCurrency;

export const isRegularCheckout = (checkoutItems: CheckoutItem[]) => {
  return (
    checkoutItems &&
    checkoutItems.length >= 1 &&
    !checkoutItems.some(isAwardFlightItem) &&
    !checkoutItems.some(isAwardTaxItem) &&
    !checkoutItems.some(isTravelExtraWithPoints)
  );
};

export const isTravelExtraPointsCheckout = (checkoutItems: CheckoutItem[]) => {
  return checkoutItems && checkoutItems.length >= 1 && checkoutItems.some(isTravelExtraWithPoints);
};

export const isTravelExtraCheckoutItemWithPointsAndMonetaryPrice = (
  item: CheckoutItem
): item is CheckoutItemWithPointsAndMonetaryPrice =>
  !!item.price.points && !!item.price.monetary && isTravelExtra(item);

export const ancillaryCheckoutItemTypes = [
  CheckoutItemType.EXTRA_BAGGAGE,
  CheckoutItemType.MEAL,
  CheckoutItemType.SEAT,
  CheckoutItemType.WIFI,
  CheckoutItemType.LOUNGE,
  CheckoutItemType.CANCELLATION_COVER,
  CheckoutItemType.PET,
  CheckoutItemType.BICYCLE,
  CheckoutItemType.DEFAULT,
];

export const isSliderCheckoutItem = (item: CheckoutItem): item is CheckoutItemWithOnlyMonetaryPrice =>
  item.splitWithPointsSupported && !!item.price.monetary && !item.price.points;
