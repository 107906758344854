import { NotificationData, Severity } from '@checkout/shared/src/model';

export const fcomBaseUrl = 'https://www.finnair.com';

// Only view types we are interested in are disruption related
export enum CmsViewTypeName {
  DISRUPTION_LEVEL_1 = 'disruption-level-1',
  DISRUPTION_LEVEL_2 = 'disruption-level-2',
  DISRUPTION_LEVEL_3 = 'disruption-level-3',
  DISRUPTION_LEVEL_4 = 'disruption-level-4',
}

const disruptionLevelMap = {
  [CmsViewTypeName.DISRUPTION_LEVEL_1]: Severity.Success,
  [CmsViewTypeName.DISRUPTION_LEVEL_2]: Severity.Info,
  [CmsViewTypeName.DISRUPTION_LEVEL_3]: Severity.Warning,
  [CmsViewTypeName.DISRUPTION_LEVEL_4]: Severity.Alert,
};

export interface DisturbanceMessageItem {
  contentId: number;
  viewTypeName: CmsViewTypeName;
  teaserTitle: string;
  linkText?: string;
  target?: {
    teaserTitle?: string;
    url: string;
  };
}

const isBannerCloseable = (severity: Severity) => severity === Severity.Success || severity === Severity.Info;

export const mapGlobalDisturbanceBannerToNotification = (item: DisturbanceMessageItem): NotificationData => {
  let readMoreUrl: string | undefined;
  if (item.target?.url) {
    readMoreUrl = item.target.url.startsWith('https') ? item.target.url : `${fcomBaseUrl}${item.target.url}`;
  }
  return {
    id: `${item.contentId}`,
    text: item.teaserTitle,
    severity: disruptionLevelMap[item.viewTypeName],
    readMoreUrl,
    readMoreText: item.linkText ?? item.target?.teaserTitle ?? undefined,
    isCloseable: isBannerCloseable(disruptionLevelMap[item.viewTypeName]),
  };
};
