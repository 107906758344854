import { Big } from 'big.js';
import { CheckoutItem, Price } from '../model';
import { resolveNumberOfDecimals, toPrice } from './payment';

const AVIOS_PER_EURO = 400 * (2 / 3);

// Big.js rounding constant, see http://mikemcl.github.io/big.js/#round
const ROUND_UP = 3;

export const convertToPointsPrice = (amount: string): string => Big(amount).mul(AVIOS_PER_EURO).toFixed(0);

export const getPriceAsPoints = (price: Price | undefined): number => {
  if (!price) return 0;
  const { amount, exchangeRate } = price;
  const pointsPerCash = getPointsPerCashValue(exchangeRate);
  return Number(Big(amount).mul(pointsPerCash).toFixed(0));
};

const isValidExchangeRate = (exchangeRate?: string): boolean => !!exchangeRate && Big(exchangeRate).gt(0);

export const getPointsPerCashValue = (exchangeRate?: string) =>
  exchangeRate && isValidExchangeRate(exchangeRate) ? Big(AVIOS_PER_EURO).div(Big(exchangeRate)).toNumber() : 0;

export const getRemainingMonetaryAmount = (points: number, originalPrice: Price): number => {
  const { amount, exchangeRate } = originalPrice;

  const originalPriceAsNumber = Number(Big(amount).toFixed(resolveNumberOfDecimals(amount)));

  if (points === 0 || !isValidExchangeRate(exchangeRate)) {
    return originalPriceAsNumber;
  }

  const P = points;
  const A = originalPriceAsNumber;
  const C = getPointsPerCashValue(exchangeRate);

  if (P === getPriceAsPoints(originalPrice)) {
    return 0;
  }

  // 3.5 * A - (0.5 * Math.sqrt(A) * Math.sqrt(25 * C * A + 24 * P)) / Math.sqrt(C);
  let toBePaidWithCash = Number(
    Big(3.5)
      .mul(A)
      .minus(
        Big(
          Big(0.5)
            .mul(Big(A).sqrt())
            .mul(Big(25).mul(C).mul(A).plus(Big(24).mul(P)).sqrt())
        ).div(Big(C).sqrt())
      )
  );

  if (Object.is(toBePaidWithCash, -0)) {
    // 'cause 100% points result is -0
    toBePaidWithCash = 0;
  }

  return Number(Big(toBePaidWithCash).round(2, ROUND_UP));
};

export const getRemainingMonetaryPrice = (points: number, originalPrice: Price): Price =>
  toPrice(
    getRemainingMonetaryAmount(points, originalPrice).toFixed(resolveNumberOfDecimals(originalPrice.amount)),
    originalPrice.currencyCode
  ) ?? toPrice(0, originalPrice.currencyCode);

export const getSplitItemPointsPrice = (item: CheckoutItem, exchangeRate: string): number => {
  if (!item.price.monetary) return 0;

  const { amount, currency } = item.price.monetary;
  return getPriceAsPoints(toPrice(amount, currency, exchangeRate));
};
