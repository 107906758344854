import { Big } from 'big.js';
import {
  CheckoutItemPayment,
  PaymentIdPrefix,
  PaymentType,
  PointPayment,
  Price,
  SurchargingRuleWithLogo,
} from '../model';
import { isIn } from './dataUtils';
import { isNumber, isString } from './typeUtils';

export const ONLINE_BANKS = [
  PaymentType.NORDEA,
  PaymentType.NORDEA_ECREDIT,
  PaymentType.NORDEA_EE,
  PaymentType.SAMPO,
  PaymentType.OKO,
  PaymentType.HANDELSBANKEN,
  PaymentType.ALANDSBANKEN,
  PaymentType.SPANKKI,
  PaymentType.AKTIA,
  PaymentType.SP,
  PaymentType.POP,
];

const EXTERNAL_REDIRECT_PAYMENT_METHODS = [
  PaymentType.ALIPAY,
  PaymentType.ALIPAY_WAP,
  PaymentType.WECHAT,
  PaymentType.UNIONPAY,
  PaymentType.SOFORT,
  PaymentType.GIROPAY,
  PaymentType.SWISH,
  PaymentType.VIPPS,
  PaymentType.IDEAL,
  PaymentType.ADYEN_SIMULATOR,
  PaymentType.TRUSTLY,
  PaymentType.MOBILE_PAY,
  PaymentType.KLARNA,
  PaymentType.KLARNA_ACCOUNT,
  PaymentType.KLARNA_PAYNOW,
  PaymentType.INDIA_WALLET,
  PaymentType.INDIA_UPI,
  PaymentType.INDIA_NETBANKING,
  PaymentType.ADYEN_PAYMENT_CARD,
];

const EXTERNAL_ONE_CLICK_PAYMENT_METHODS = [PaymentType.APPLE_PAY];

const SPLIT_ITEM_PAYMENTID_PREFIXES = [PaymentIdPrefix.GIFT_CARD, PaymentIdPrefix.POINTS_PAYMENT];

export const REDIRECTION_PAYMENT_METHODS = [...ONLINE_BANKS, ...EXTERNAL_REDIRECT_PAYMENT_METHODS];

export const ADYEN_COMPONENT_METHODS: PaymentType[] = [
  PaymentType.SWISH,
  PaymentType.UNIONPAY,
  PaymentType.IDEAL,
  PaymentType.ALIPAY,
  PaymentType.ALIPAY_WAP,
  PaymentType.GIROPAY,
  PaymentType.MOBILE_PAY,
  PaymentType.SOFORT,
  PaymentType.VIPPS,
  PaymentType.WECHAT,
  PaymentType.KLARNA,
  PaymentType.KLARNA_PAYNOW,
  PaymentType.KLARNA_ACCOUNT,
  PaymentType.INDIA_WALLET,
  PaymentType.INDIA_UPI,
  PaymentType.INDIA_NETBANKING,
  PaymentType.ADYEN_PAYMENT_CARD,
];

export const paymentTypeToAdyenComponentId = (adyenComponentMethod: PaymentType) => {
  if (adyenComponentMethod === PaymentType.SOFORT) return 'directEbanking';
  if (adyenComponentMethod === PaymentType.ALIPAY_WAP) return 'alipay_wap';
  if (adyenComponentMethod === PaymentType.WECHAT) return 'wechatpay';
  if (adyenComponentMethod === PaymentType.INDIA_WALLET) return 'wallet_IN';
  if (adyenComponentMethod === PaymentType.INDIA_NETBANKING) return 'onlinebanking_IN';
  if (adyenComponentMethod === PaymentType.INDIA_UPI) return 'upi';
  if (adyenComponentMethod === PaymentType.ADYEN_PAYMENT_CARD) return 'card';
  if (adyenComponentMethod === PaymentType.KLARNA_ACCOUNT || adyenComponentMethod === PaymentType.KLARNA_PAYNOW) {
    return adyenComponentMethod.toLowerCase();
  }
  return adyenComponentMethod.toLowerCase().replaceAll('_', '');
};

export const paymentTypeToAdyenIcon = (adyenComponentMethod: PaymentType) => {
  if (adyenComponentMethod === PaymentType.WECHAT) return 'wechatpay';
  if (adyenComponentMethod === PaymentType.INDIA_UPI) return 'upi';
  if (adyenComponentMethod === PaymentType.ALIPAY_WAP) return 'alipay';
  if (adyenComponentMethod === PaymentType.KLARNA_ACCOUNT || adyenComponentMethod === PaymentType.KLARNA_PAYNOW) {
    return 'klarna';
  }
  return adyenComponentMethod.toLowerCase().replaceAll('_', '');
};

const EXTERNAL_PAYMENT_METHODS = [...EXTERNAL_REDIRECT_PAYMENT_METHODS, ...EXTERNAL_ONE_CLICK_PAYMENT_METHODS];

export const isRedirectionPaymentType = (pt?: PaymentType) => {
  return isIn(pt, REDIRECTION_PAYMENT_METHODS);
};

export const isExternalPaymentType = (pt?: PaymentType): pt is PaymentType => {
  return isIn(pt, EXTERNAL_PAYMENT_METHODS);
};

export const isOnlineBankPaymentType = (pt: PaymentType | null) => {
  return isIn(pt, ONLINE_BANKS);
};

export const isCardPaymentType = (pt?: PaymentType): pt is PaymentType =>
  isIn(pt, [PaymentType.PAYMENT_CARD, PaymentType.CREDIT_TOKEN, PaymentType.DEBIT_TOKEN]);

export const isSplitPaymentType = (pt: PaymentType): boolean => isIn(pt, [PaymentType.GIFT_CARD, PaymentType.POINTS]);

export const isSplitItemPayment = (p: CheckoutItemPayment): boolean =>
  SPLIT_ITEM_PAYMENTID_PREFIXES.some((splitItemPayment) => p.paymentId.startsWith(splitItemPayment));

type SurchargingRuleMatcherFunction = (rule: SurchargingRuleWithLogo) => boolean;

export const createRuleFindFunction =
  (vendor: string, corporate: boolean, cardType: string): SurchargingRuleMatcherFunction =>
  (rule: SurchargingRuleWithLogo) =>
    rule.vendor === vendor && rule.corporate === corporate && rule.cardType === cardType;

export const isSurchargeable = (paymentType: PaymentType): boolean =>
  [PaymentType.CREDIT_TOKEN, PaymentType.DEBIT_TOKEN, PaymentType.PAYMENT_CARD].includes(paymentType);

export const toPrice = (amount: string | number | undefined, currencyCode: string, exchangeRate?: string): Price => {
  if (isString(amount)) {
    return {
      currencyCode,
      amount,
      exchangeRate,
    };
  } else if (isNumber(amount)) {
    return {
      currencyCode,
      amount: amount.toFixed(2),
      exchangeRate,
    };
  }

  return {
    currencyCode,
    amount: '0.00',
    exchangeRate,
  };
};

export const resolveNumberOfDecimals = (amount: string): number =>
  amount.indexOf('.') !== -1 ? amount.length - amount.indexOf('.') - 1 : 0;

/**
 * Splits the amount into integers according to ratios.
 * @example allocate(10, [1,1,1]) => [4,3,3]
 */
export const allocate = (amount: number, ratios: number[]) => {
  const sum = ratios.reduce((res, value) => res + value, 0);
  const ratio = amount / sum;
  const leftOver = Math.round(ratios.reduce((fractions, value) => fractions + ((value * ratio) % 1), 0));
  return ratios.map((value, idx) => Math.floor(value * ratio) + Number(idx < leftOver));
};

export const allocateMonetaryValues = (amount: string, ratios: string[], numberOfDecimals: number): string[] => {
  const toMinorCurrency = (i: string): number => Big(i).times(Math.pow(10, numberOfDecimals)).toNumber();
  const toMajorCurrency = (i: number): string => Big(i).div(Math.pow(10, numberOfDecimals)).toFixed(numberOfDecimals);
  const adjustedRatios = ratios.map(toMinorCurrency); // Convert to minor currency
  const adjustedAmount = Math.min(
    toMinorCurrency(amount),
    adjustedRatios.reduce((sum, i) => i + sum, 0)
  );
  return allocate(adjustedAmount, adjustedRatios).map(toMajorCurrency);
};

export const mapPointsPaymentId = (pointspayment: Omit<PointPayment, 'paymentId'>[]): PointPayment[] =>
  pointspayment.map((payment, idx) => ({
    ...payment,
    paymentId: `${PaymentIdPrefix.POINTS_PAYMENT}-${idx + 1}`,
  }));

export const MINIMUM_KLARNA_ACCOUNT_AMOUNT_EUR = 25.0;
export const MINIMUM_KLARNA_ACCOUNT_AMOUNT_SEK = 250;

export const hasMinimumAmountAllowedForKlarnaAccount = ({ amount, currencyCode }: Price): boolean =>
  (currencyCode === 'EUR' && parseFloat(amount || '0') >= MINIMUM_KLARNA_ACCOUNT_AMOUNT_EUR) ||
  (currencyCode === 'SEK' && parseFloat(amount || '0') >= MINIMUM_KLARNA_ACCOUNT_AMOUNT_SEK);

export const logoUrlToIconName = (href: string) => {
  const start = href.lastIndexOf('/') + 1;
  const end = href.lastIndexOf('_');
  return href.slice(start, end).replaceAll('_', '');
};
