export const LOCALE_TO_DATE_TIME_FORMAT: Record<string, Record<string, string>> = {
  default: {
    format: 'en-GB',
    timeZone: 'UTC',
  },
  da: {
    format: 'da',
    timeZone: 'Europe/Copenhagen',
  },
  de: {
    format: 'de',
    timeZone: 'Europe/Berlin',
  },
  en: {
    format: 'en-GB',
    timeZone: 'UTC',
  },
  es: {
    format: 'es',
    timeZone: 'Europe/Madrid',
  },
  et: {
    format: 'et',
    timeZone: 'Europe/Tallinn',
  },
  fi: {
    format: 'fi-FI',
    timeZone: 'Europe/Helsinki',
  },
  fr: {
    format: 'fr',
    timeZone: 'Europe/Paris',
  },
  it: {
    format: 'it',
    timeZone: 'Europe/Rome',
  },
  ja: {
    format: 'ja',
    timeZone: 'Asia/Tokyo',
  },
  ko: {
    format: 'ko',
    timeZone: 'Asia/Seoul',
  },
  no: {
    format: 'no',
    timeZone: 'Europe/Oslo',
  },
  pl: {
    format: 'pl',
    timeZone: 'Europe/Warsaw',
  },
  ru: {
    format: 'ru',
    timeZone: 'Europe/Moscow',
  },
  sv: {
    format: 'sv',
    timeZone: 'Europe/Stockholm',
  },
  zh: {
    format: 'zh',
    timeZone: 'Asia/Shanghai',
  },
};

export interface LocaleDateFields {
  yyyy: number;
  MM: string;
  M: number;
  d: number;
  dd: string;
  HH: string;
  H: number;
  mm: string;
  ss: string;
  tz?: string;
}

export const pad = (value: string, minLength = 2, placeholder = '0') => {
  const count = minLength - value.toString().length;
  return (count > 0 ? new Array(count + 1).join(placeholder) : '') + value;
};

export const localDateFields = (dateParameter: Intl.DateTimeFormatPart[]): LocaleDateFields => {
  const partsObj = dateParameter.reduce(
    (acc, curr) => {
      acc[curr.type] = pad(curr.value);
      return acc;
    },
    {} as Record<string, string>
  );
  const yyyy = parseInt(partsObj.year);
  const MM = partsObj.month;
  const dd = partsObj.day;
  const HH = partsObj.hour;
  const mm = partsObj.minute;
  const ss = partsObj.second;
  return {
    yyyy,
    MM,
    M: parseInt(MM, 10),
    dd,
    d: parseInt(dd, 10),
    HH,
    H: parseInt(HH, 10),
    mm,
    ss,
    tz: partsObj.timeZoneName,
  };
};

export const getDateTimeFormat = (date: Date, locale: string): string => {
  const supportedLocales = Object.keys(LOCALE_TO_DATE_TIME_FORMAT);
  const langPart = supportedLocales.includes(locale) ? locale : 'default';
  const { format, timeZone } = LOCALE_TO_DATE_TIME_FORMAT[langPart];
  const dtfParts = Intl.DateTimeFormat(format, { timeZone, dateStyle: 'short', timeStyle: 'long' }).formatToParts(date);
  const localeDate = date.toLocaleDateString(format, { timeZone, dateStyle: 'short' });
  const localeTime = date.toLocaleTimeString(format, { timeZone, timeStyle: 'short' });
  const { tz } = localDateFields(dtfParts);
  return `${localeDate}, ${localeTime} (${tz})`;
};
