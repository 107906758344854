export enum Stage {
  Local = 'local',
  Development = 'dev',
  Test = 'test',
  Preproduction = 'preprod',
  Production = 'prod',
  UnitTest = 'unittest',
}

export const getStage = (value?: string): Stage => {
  const validStages = Object.values(Stage) as unknown as string[];
  const stage = value ?? process.env.STAGE ?? Stage.Local;
  if (validStages.includes(stage)) {
    return stage as unknown as Stage;
  }
  throw Error(`Invalid STAGE env var, use one of: ${validStages}`);
};
