// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require('dayjs');

export const addDays = (date: Date, days: number): Date => dayjs(date).add(days, 'day').toDate();

export const addMonths = (date: Date, months: number): Date => dayjs(date).add(months, 'month').toDate();

export const addSeconds = (date: Date, seconds: number): Date => dayjs(date).add(seconds, 'second').toDate();

export const compareDateStrings = (a: string, b: string) => toDate(a).getTime() - toDate(b).getTime();

export const toDate = (dateString: string): Date => dayjs(dateString).toDate();

export const toDateWithoutTime = (dateString: string): Date => dayjs(dateString.split('T')[0]).toDate();

export const isEqual = (a: string, b: string) => compareDateStrings(a, b) === 0;
