/* istanbul ignore file */

import {
  ApplyGiftCardRequest,
  ApplyGiftCardResponse,
  ApplyPointsRequest,
  AuthorizeResponse,
  Checkout,
  SelectPaymentMethodParameters,
  StoreCardinalSessionRequest,
  StoreChallengeResponseRequest,
} from '@checkout/shared/src/model';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { SelectPaymentResponse } from '../../api/src/models/gateway.interface';
import { PointsData } from '../../api/src/repository/database-model';
import { getAxiosAuthorizationConfig } from './authorize';
import { ENV } from './envVars';

// Error format used on backend
type ErrorInfo = {
  error: string;
  timestamp: Date;
  uuid: string;
};

const config = getAxiosAuthorizationConfig();

type Result<R> = { success: true; data: R } | { success: false; info?: ErrorInfo };

const handleSuccess = <R>(res: AxiosResponse<R>): Result<R> => ({
  success: true,
  data: res.data,
});

const handleError = <R>(error: AxiosError<ErrorInfo>): Result<R> => {
  const data = error.response?.data;
  return {
    success: false,
    info: data && { ...data, timestamp: new Date(data.timestamp) },
  };
};

const GET = async <R>(url: string, configExtension?: Record<string, unknown>) =>
  axios
    .get<R>(url, { ...config, ...configExtension })
    .then(handleSuccess)
    .catch(handleError) as Promise<Result<R>>;

const POST = async <R, B = unknown>(url: string, body?: B) =>
  axios.post<B, AxiosResponse<R>>(url, body, config).then(handleSuccess).catch(handleError) as Promise<Result<R>>;

const DELETE = async <R>(url: string) =>
  axios.delete<R>(url, config).then(handleSuccess).catch(handleError) as Promise<Result<R>>;

export const applyGiftCard = async (checkoutId: string, req: ApplyGiftCardRequest) =>
  POST<ApplyGiftCardResponse>(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/payments/gift-cards`, req);

export const removeGiftCard = async (checkoutId: string) =>
  DELETE(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/payments/gift-cards`);

export const selectPaymentMethod = async (selectionUrl: string, params?: SelectPaymentMethodParameters) =>
  POST<SelectPaymentResponse>(selectionUrl, params);

export const authorizeApplePay = async (authorizationUrl: string, tokenBase64: string) =>
  axios.post<{ token: string }, AxiosResponse<AuthorizeResponse>>(authorizationUrl, { token: tokenBase64 }, config);

export const updateStatusToRedirected = async (checkoutId: string) =>
  POST(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/redirect`);

export const updateStatusToReadyForPayment = async (checkoutId: string, resetSource: string) =>
  POST(
    `${ENV.checkoutApiUrl}/checkout/${checkoutId}/reset?resetSource=${resetSource}&shouldUpdateToReadyForPayment=true`
  );

export const updateStatusToInvalidPaymentMethod = async (checkoutId: string, resetSource: string) =>
  POST(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/reset?resetSource=${resetSource}`);

export const payWithFinnairPayments = async (checkoutId: string) =>
  POST(`${ENV.checkoutApiUrl}/oneclick/${checkoutId}`);

export const fetchCheckout = async (checkoutId: string, shouldRetry = true) =>
  GET<Checkout>(`${ENV.checkoutApiUrl}/checkout/${checkoutId}`, {
    ...(!shouldRetry && { 'axios-retry': { retries: 0 } }),
  });

export const applyPoints = async (checkoutId: string, data: ApplyPointsRequest) =>
  POST<PointsData[]>(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/payments/points`, data);

export const persistCardinalSessionId = async (checkoutId: string, data: StoreCardinalSessionRequest) =>
  POST(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/3ds/cardinal-session`, data);

export const persistCardinalChallengeResponseJWT = async (checkoutId: string, data: StoreChallengeResponseRequest) =>
  POST(`${ENV.checkoutApiUrl}/checkout/${checkoutId}/3ds/challenge-response`, data);
