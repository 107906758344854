import { Big } from 'big.js';
import { CheckoutItem, Payments, Price, SurchargingRule, CheckoutItemPayment } from '../model';
import { resolveNumberOfDecimals, toPrice, isSplitItemPayment } from './payment';
import { getRemainingPrice } from './split-payment';
import { isNotPresent, isPresent } from './typeUtils';
import { hasMonetaryPrice } from '.';

export const calculateSurchargeAmount = (amount?: string | null, percentage?: string | null): string =>
  isPresent(amount) && isPresent(percentage) && parseFloat(amount) > 0 && parseFloat(percentage) > 0
    ? Big(amount).times(Big(percentage).div(100)).toFixed(resolveNumberOfDecimals(amount), Big.roundDown)
    : '0';

export const calculateTotalAmount = (total: string, surchargeAmount: string, percentage: string): string =>
  Big(total)
    .plus(Big(calculateSurchargeAmount(surchargeAmount, percentage) || 0))
    .toFixed(resolveNumberOfDecimals(total));

export type RemainingPriceAndSurcharge = {
  remainingPrice: Price | undefined;
  surcharge: Price | undefined;
};

interface RemainingPriceAndItemId {
  remainingPrice: string;
  itemId: string;
}

export const calculateRemainingSurcharge = (
  totalPrice: Price,
  surchargeablePrice: Price | undefined,
  remainingPrice: Price
): Price | undefined => {
  if (isNotPresent(surchargeablePrice)) {
    return undefined;
  }

  const priceNotToSurcharge = Big(totalPrice.amount).minus(surchargeablePrice.amount);

  if (Big(remainingPrice.amount).lte(priceNotToSurcharge)) {
    return toPrice(0, totalPrice.currencyCode);
  }
  const amountToSurcharge = Big(remainingPrice.amount).minus(priceNotToSurcharge);
  const numberOfDigits = resolveNumberOfDecimals(surchargeablePrice.amount);

  return toPrice(amountToSurcharge.toFixed(numberOfDigits), totalPrice.currencyCode);
};

const getPriceWithSurcharge = (
  totalPrice: Price,
  remainingPrice: Price,
  surchargeToBePaid: string
): RemainingPriceAndSurcharge => {
  const numberOfDecimals: number = resolveNumberOfDecimals(totalPrice.amount);
  const total: string = Big(remainingPrice.amount).plus(surchargeToBePaid).toFixed(numberOfDecimals);

  const surchargeAmount: string = Big(surchargeToBePaid).toFixed(numberOfDecimals);
  const { currencyCode, exchangeRate } = totalPrice;
  return {
    remainingPrice: toPrice(total, currencyCode, exchangeRate),
    surcharge: Big(surchargeToBePaid).eq(0) ? undefined : toPrice(surchargeAmount, currencyCode, exchangeRate),
  };
};

export const calculatePriceWithSurchargeAndPoints = (
  totalPrice: Price,
  payments: Payments,
  checkoutItems: CheckoutItem[],
  itemPayments?: CheckoutItemPayment[],
  rule?: SurchargingRule
): RemainingPriceAndSurcharge => {
  const remainingPrice: Price = getRemainingPrice(totalPrice, payments, checkoutItems);
  const surchargeToBePaid: string = isPresent(rule)
    ? getCheckoutItemsSurcharge(checkoutItems, totalPrice, itemPayments, rule.percentage)
    : '0';

  return getPriceWithSurcharge(totalPrice, remainingPrice, surchargeToBePaid);
};

const getRemainingItemPriceAfterSplitPayment = (
  item: CheckoutItem,
  decimals: number,
  itemPayments?: CheckoutItemPayment[]
): string => {
  const {
    price: { monetary },
  } = item;
  if (!monetary) return Big(0).toFixed(decimals);
  const payment = (itemPayments || []).filter(isSplitItemPayment).find((p) => p.itemId === item.id);
  return Big(monetary.amount)
    .minus(payment?.amount || 0)
    .toFixed(decimals);
};

export const getCheckoutItemsSurcharge = (
  checkoutItems: CheckoutItem[],
  totalPrice: Price,
  itemPayments?: CheckoutItemPayment[],
  percentage?: string | null
): string => {
  const decimals = resolveNumberOfDecimals(totalPrice.amount);
  const remainingPrices: RemainingPriceAndItemId[] = checkoutItems
    .filter(hasMonetaryPrice)
    .filter((item) => item.surchargingApplies)
    .map((item) => ({
      remainingPrice: getRemainingItemPriceAfterSplitPayment(item, decimals, itemPayments),
      itemId: item.id,
    }));
  const surchargeablePrices = getSurchargeablePrices(remainingPrices, checkoutItems);
  return surchargeablePrices
    .reduce((sum, curr) => {
      return sum.plus(calculateSurchargeAmount(curr.remainingPrice, percentage));
    }, Big(0))
    .toFixed(decimals);
};

export const getItemsWithSamePriceAndSurcharge = (itemId: string, checkoutItems: CheckoutItem[]): CheckoutItem[] => {
  const item = checkoutItems.find((i) => i.id === itemId);
  return checkoutItems
    .filter((i) => i.surchargingApplies)
    .filter((i) => i.type === item?.type)
    .filter((i) => i.payableWithGiftCard === item?.payableWithGiftCard)
    .filter((i) => i.splitWithPointsSupported === item?.splitWithPointsSupported)
    .filter((i) => i.price.monetary?.amount === item?.price.monetary?.amount);
};

const getSurchargeablePrices = (prices: RemainingPriceAndItemId[], checkoutItems: CheckoutItem[]) =>
  prices.map(({ itemId }) => {
    const surchargeableItemIds = getItemsWithSamePriceAndSurcharge(itemId, checkoutItems).map((i) => i.id);
    return (
      prices
        .filter((p) => surchargeableItemIds.includes(p.itemId))
        // get item with lowest remaining price ater possible split payment
        .reduce((prev, curr) => (Big(prev.remainingPrice).lt(curr.remainingPrice) ? prev : curr))
    );
  });
