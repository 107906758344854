export const isNull = (obj: unknown): obj is null => obj === null && typeof obj === 'object';
export const isNotNull = <T>(obj: T | null): obj is T => !isNull(obj);

export const isUndefined = (obj: unknown): obj is undefined => typeof obj === 'undefined';
export const isNotUndefined = <T>(obj: T | undefined): obj is T => !isUndefined(obj);

export const isNotPresent = (obj: unknown): obj is null | undefined => isNull(obj) || isUndefined(obj);
export const isPresent = <T>(obj: T | undefined | null): obj is T => !isNotPresent(obj);

export const isArray = (arr: unknown): arr is unknown[] => {
  return Array.isArray(arr);
};

export const isObject = (obj: unknown): obj is Record<string, unknown> => isPresent(obj) && typeof obj === 'object';

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (func: unknown): func is Function => isPresent(func) && func instanceof Function;

export const isString = (obj: unknown): obj is string => isPresent(obj) && typeof obj === 'string';
export const isNumber = (obj: unknown): obj is number => isPresent(obj) && typeof obj === 'number';

export type Result<T, E = undefined> = { ok: true; value: T } | { ok: false; error: E | undefined };

export const Ok = <T>(data: T): Result<T, never> => {
  return { ok: true, value: data };
};

export const Err = <E>(error?: E): Result<never, E> => {
  return { ok: false, error };
};
