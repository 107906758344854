import axios from 'axios';
import { merge } from 'lodash';
import { DisturbanceMessageItem, mapGlobalDisturbanceBannerToNotification } from './globalDisturbanceBanner';
import newEn = require('./texts/new.en.json');
import newFi = require('./texts/new.fi.json');
import { NotificationData } from '@checkout/shared/src/model/payment.interface';

export const cmsBaseUrl = 'https://cms.finnair.com';

export const getTexts = async (locale: Locale) => merge(getNewTexts(locale), await getCmsTexts(locale)) as Texts;

export const parseLocale = (locale: string): Locale =>
  supportedLocales.includes(locale as Locale) ? (locale as Locale) : 'en';

export const getCmsTexts = async (locale: Locale) => {
  const cmsUrl = `${cmsBaseUrl}/service/json/v1/paths/${locale}/translations/pay-finnair-com-ui?properties=localSettings,url&nestingDepth=1&duplicates=true`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = await axios.get<{ localSettings: any }>(cmsUrl);
  if (res.status !== 200) throw new Error(res.statusText);
  return res.data.localSettings;
};

// TODO: Move this to create checkout as Brave will probably block this too as it was blocking the Terms and Conditions from loading
// Handle gracefully if we're unable to get global disturbance content
export const getGlobalDisturbanceBanners = async (bannerUrl: string): Promise<NotificationData[]> => {
  try {
    const res = await axios.get<{ items: DisturbanceMessageItem[] }>(bannerUrl);
    if (res.status !== 200) return [];
    const items = res.data.items ?? [];
    return items.map(mapGlobalDisturbanceBannerToNotification);
  } catch (err) {
    return [];
  }
};

export const getTermsAndConditionsContents = async (url: string): Promise<{ content: string; title: string }> => {
  const response = (await axios.get<{ detailText: string; teaserTitle: string }>(url))?.data;
  if (!(response?.teaserTitle && response?.detailText)) {
    throw new Error(`Unable to get terms and conditions. Response was ${response}`);
  }
  return { content: response.detailText, title: response.teaserTitle };
};

export const getNewTexts = (locale: Locale) => {
  const lang = locale.split('-')[1];
  if (lang === 'fi') return newFi;
  return newEn;
};

export interface Texts {
  [key: string]: Texts | string;
}

export const supportedLocales = [
  'en',
  'at-de',
  'at-en',
  'au-en',
  'be-en',
  'ca-en',
  'ca-fr',
  'ch-de',
  'ch-en',
  'ch-fr',
  'cn-en',
  'cn-zh',
  'cz-en',
  'de-de',
  'de-en',
  'dk-da',
  'dk-en',
  'ee-en',
  'ee-et',
  'es-en',
  'es-es',
  'fi-en',
  'fi-fi',
  'fi-sv',
  'fr-en',
  'fr-fr',
  'gb-en',
  'hk-en',
  'hk-zh',
  'hu-en',
  'ie-en',
  'il-en',
  'in-en',
  'is-en',
  'it-en',
  'it-it',
  'jp-en',
  'jp-ja',
  'kr-en',
  'kr-ko',
  'lt-en',
  'lv-en',
  'nl-en',
  'no-en',
  'no-no',
  'pl-en',
  'pl-pl',
  'pt-en',
  'ru-en',
  'ru-ru',
  'se-en',
  'se-sv',
  'sg-en',
  'th-en',
  'us-en',
] as const;
export type Locale = (typeof supportedLocales)[number];
