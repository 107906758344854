import { Big } from 'big.js';
import { AppliedGiftCard, CheckoutItem, Payments, PointPayment, Price } from '../model';
import { isNotEmpty } from './dataUtils';
import { resolveNumberOfDecimals, toPrice, allocate } from './payment';
import { getRemainingMonetaryAmount, getSplitItemPointsPrice, getPointsPerCashValue } from './points';

export const getMinPointsForSplitPayment = (checkoutItems: CheckoutItem[], totalPrice?: Price): number => {
  if (!totalPrice?.exchangeRate || hasSplitPaymentSupportedItems(checkoutItems)) return Infinity;
  const { amount, exchangeRate } = totalPrice;
  const totalPercentage = 100;
  const decimals = resolveNumberOfDecimals(amount);
  const itemPricesInPoints = checkoutItems
    .filter((i) => i.splitWithPointsSupported)
    .map((item) => getSplitItemPointsPrice(item, exchangeRate));
  const itemPriceRatios = allocate(totalPercentage, itemPricesInPoints);
  const lowestPricePercentage = Math.min(...itemPriceRatios);
  // If lowest item price in percentages is zero, return Infinity but this shouldn't happen ever (or not before we have closer to 100 items)
  if (lowestPricePercentage == 0) return Infinity;
  const percentage = Big(lowestPricePercentage).div(totalPercentage);
  const pointsCostPerSmallestCashValue = Big(getPointsPerCashValue(exchangeRate)).div(Big(10).pow(decimals));
  // If smallest allocation has the smallest value allowed, the unknown total is smallestAllocation/percentage
  const pointsTotal = pointsCostPerSmallestCashValue.div(percentage);
  return pointsTotal.round(0, Big.roundUp).toNumber();
};

const hasSplitPaymentSupportedItems = (checkoutItems: CheckoutItem[]): boolean =>
  !checkoutItems.some((item) => item.splitWithPointsSupported);

export const getRemainingPrice = (
  totalPrice: Price,
  { giftCards, points }: Payments,
  checkoutItems: CheckoutItem[] = []
): Price => {
  const giftCardSum = giftCards ? getSumOfGiftCards(giftCards) : 0;
  const pointsFullySum = points ? getSumOfFullPointsPayments(points, checkoutItems) : 0;
  const pointsSplitSum = points && totalPrice ? getSumOfSplitPointsPayments(points, checkoutItems, totalPrice) : 0;
  const numberOfDecimals = resolveNumberOfDecimals(totalPrice.amount);
  const remainingSum = Big(totalPrice.amount).minus(giftCardSum).minus(pointsFullySum).minus(pointsSplitSum);
  return toPrice(remainingSum.toFixed(numberOfDecimals), totalPrice.currencyCode, totalPrice.exchangeRate);
};

export const getSumOfGiftCards = (giftCards: AppliedGiftCard[]) =>
  giftCards.reduce((sum, gc) => sum.add(Big(gc.appliedValue || '0')), new Big(0));

const getSumOfFullPointsPayments = (pointPayments: PointPayment[], checkoutItems: CheckoutItem[]) =>
  pointPayments
    .flatMap((p) => {
      const item = checkoutItems.find((i) => i.id === p.checkoutItemId);
      if (!item?.price.monetary || item.splitWithPointsSupported) return [];
      return [item.price.monetary];
    })
    .reduce((sum, p) => sum.add(Big(p.amount)), new Big(0));

export const getSumOfSplitPointsPayments = (
  pointPayments: PointPayment[],
  checkoutItems: CheckoutItem[],
  { currencyCode, exchangeRate }: Price
) => {
  const { monetary, points } = pointPayments
    .flatMap((p) => {
      const item = checkoutItems.find((i) => i.id === p.checkoutItemId);
      if (!item?.price.monetary || !item.splitWithPointsSupported) return [];
      return [{ monetary: Big(item.price.monetary.amount), points: Big(p.amount) }];
    })
    .reduce(
      (sum, p) => ({
        monetary: sum.monetary.add(p.monetary),
        points: sum.points.add(p.points),
      }),
      { monetary: new Big(0), points: new Big(0) }
    );
  const remainingAmount = getRemainingMonetaryAmount(
    points.toNumber(),
    toPrice(monetary.toNumber(), currencyCode, exchangeRate)
  );
  return monetary.minus(Big(remainingAmount));
};

export const getPriceOfPayments = (
  { giftCards, points }: Payments,
  totalPrice: Price | undefined,
  checkoutItems: CheckoutItem[] = []
): Price => {
  let sum = Big(0);
  const numberOfDecimals = totalPrice
    ? resolveNumberOfDecimals(totalPrice.amount)
    : giftCards && resolveNumberOfDecimals(giftCards[0].appliedValue || '0.00');
  const currencyCode = totalPrice ? totalPrice.currencyCode : giftCards && giftCards[0].currency;

  if (isNotEmpty(giftCards)) {
    sum = sum.plus(getSumOfGiftCards(giftCards));
  }
  if (totalPrice && isNotEmpty(points)) {
    sum = sum.plus(getSumOfSplitPointsPayments(points, checkoutItems, totalPrice));
  }
  return toPrice(sum.toFixed(numberOfDecimals ?? 2), currencyCode ?? 'EUR', totalPrice?.exchangeRate ?? '1');
};
