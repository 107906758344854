import { isArray, isNotPresent, isNumber, isPresent, isString } from './typeUtils';

export const isIn = <T>(value: T, list: T[]): boolean => list.indexOf(value) !== -1;

export const isNotEmpty = <T>(arr: T[] | undefined): arr is T[] => isPresent(arr) && arr.length > 0;
export const isEmpty = <T>(arr: T[]): boolean => !isNotEmpty(arr);

interface GroupedBy<T> {
  [id: string]: T[];
  [id: number]: T[];
}

export const groupByKey = <T, K extends keyof T>(arr: T[], key: K): GroupedBy<T> => {
  return arr.reduce((acc: GroupedBy<T>, row: T) => {
    const value: T[K] = row[key];
    const groupKey: string | number = isString(value) || isNumber(value) ? value : 'undefined';

    if (!isArray(acc[groupKey])) {
      acc[groupKey] = [];
    }

    acc[groupKey] = acc[groupKey].concat(row);

    return acc;
  }, {});
};
export const unique = <A>(value: A, index: number, self: A[]): boolean => self.indexOf(value) === index;
export const isBlank = (str: string): boolean => isNotPresent(str) || str.trim().length === 0;
export const isNotBlank = (str: string): boolean => !isBlank(str);

export const getRandomFromArray = <T>(items: T[]): T => {
  const index = Math.floor(Math.random() * items.length);
  return items[index];
};
